import React, { Component } from "react";


class End extends Component {
    constructor(props) {
        super(props);
        this.state = {
            typicaltStyle: {
                opacity: '0'
            }
        }
    }
    componentDidMount() {
        setTimeout(() => {
            this.setState({
                typicaltStyle: {
                    opacity: '1'
                }
            })
        }, 100);
    }
    render() {
       
          return (<React.Fragment>
        {/* <div className="end-bg__wrap position-absolute">
            <svg strokeWidth="0.501" strokeLinejoin="bevel" fillRule="evenodd"version="1.1" overflow="visible" width="225pt" height="195.395pt" viewBox="0 0 225 195.395">
        
            <g id="Document" fill="none" stroke="black" transform="scale(1 -1)">
            <g id="Spread" transform="translate(0 -195.395)">
            <g id="Layer 1">
                <path d="M 166.198,195.395 C 142.441,195.395 121.76,181.193 112.51,160.635 C 103.26,181.173 82.579,195.395 58.822,195.395 C 26.385,195.395 0,168.989 0,136.552 C 0,63.406 105.521,3.178 110.024,0.652 C 110.798,0.224 111.634,0 112.489,0 C 113.345,0 114.201,0.224 114.955,0.652 C 119.437,3.178 225,63.406 225,136.552 C 225.02,168.989 198.635,195.395 166.198,195.395 Z" fill="#e3495d" stroke="none" strokeWidth="2.037" fillRule="evenodd" strokeLinejoin="miter" markerStart="none" markerEnd="none" strokeMiterlimit="79.8403193612775"/>
            </g>
            <g id="PRINT"/>
            <g id="Imported PRINT"/>
            </g>
            </g>
        </svg>
        </div> */}
        <div style={this.state.typicaltStyle} className="card" >
            <div className="card-body">
                <h5 className="card-title font-weight-bold">Дякуємо!</h5>
                <p className="card-text">Ми дуже вдячні за Ваш відгук.<br/> Він допоможе стати нам краще!<br/> Залишайтеся з нами!</p>
                <p className="card-text"><strong>Ваш "Золотий Вік"</strong></p>
                {/* <div href="#" className="btn btn-primary">Переход куда-нибудь</div> */}
            </div>
        </div>
        </React.Fragment>)
    }
}



export default End;