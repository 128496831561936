import react, { Component } from "react";
import Start from "./cards/Start";
import Typical from "./cards/Typical";
import End from "./cards/End";
import Header from "./Header";
import Footer from "./Footer";
import Back from "./Back";
import Preloader from "./Preloader";

class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      // startStyle : {
      //   position : 'relative'
      // },
      frames : null,
      currentquestion : 0,
      currentFrame : null,
      responses : new Array
    }
    this.Change = this.Change.bind(this);
    this.getQ = this.getQ.bind(this);
    this.CreateFrames = this.CreateFrames.bind(this);
  }



  async getQ(){
    let req = await fetch('/q/1.json');
    if(req.status == 200){
       let res = await req.json();
      //  console.log(res);
      this.setState({
        frames: res.questions.map(this.CreateFrames)
      })
       return res;
    } else {
      console.log(req);
    }
  }

  CreateFrames(item, index){
    return <Typical key={index} change={this.Change} data={item} />
  }

  componentDidMount(){
    let root = document.getElementById("root");
    let nrealH = root.getBoundingClientRect().height;
    let realH = window.innerHeight;
    root.style.height = "calc(100vh - " + (nrealH - realH) + "px)";
    this.getQ();
    this.setState({
      currentFrame: <Start change={this.Change}/>
    })
  }

  // 
    
  Change(data){
    // console.log('change');
    let current = this.state.currentquestion;
    // current++;
    // console.log(this.state.responses);
    if (this.state.frames.length > current ) {
      console.log('not yet');
      this.setState({
        responses : [...this.state.responses, data],
        currentFrame: this.state.frames[current],
        currentquestion: ++current
      })
    } else {
      console.log('now im sending!');
      this.setState({ 
        responses : [...this.state.responses, data],
        currentFrame: <End/>
      })
      this.sendData(this.state.responses);
    }
  }


  async sendData(data){
    // data = data.filter(item => item!=undefined);
    // let str = data.reduce((stri, curvalue) => {
      
    //   return curvalue === undefined ? '' : stri + curvalue.q.replace(/[^A-Za-z0-9А-Яа-я іїІЇЪъ]/gi, "") + '=' + curvalue.a.replace(/[^A-Za-z0-9А-Яа-я ії]/gi, "") + '&'
    // }, '');
    // console.log(str);

    let form = new FormData();
    


    var my_sSearchString = window.location.search;
        if (my_sSearchString.indexOf("ph=") > 0) {
            var my_index = my_sSearchString.indexOf("ph=");
            var my_string = my_sSearchString.slice(my_index + 3);
            if (my_string != "0") {
                // window.my_currentUser = {
                //     my_phone: atob(my_string)
                // };
                form.append('Телефон', atob(my_string));
                // console.log(window.my_currentUser.my_phone);
            }

        }

        data.forEach(element => {
      if (element != undefined) {
        form.append(element.q.replace(/[^A-Za-z0-9А-Яа-я іїІЇЪъ]/gi, ""), element.a.replace(/[^A-Za-z0-9А-Яа-я іїІЇЪъ]/gi, ""));
      }
    });


    console.log(form);
    let url = "https://www.zvlife.top/zv/poll/poll.php"
    let req = await fetch(url,{
      method : 'POST',
      body : form
    });
    let res = await req.text();

    console.log(res);
  }

  // Next(){
  //   // console.log('hello');
  //   this.setState({
  //     startStyle : {
  //       transform: 'translateX(-120%) rotateZ(-5deg)'
  //     }
  //   })
  // }

  render(){
    let root = document.getElementById('root')
    console.log(root);
    console.log(root.getBoundingClientRect().height);
    console.log('innerHeight ' + window.innerHeight);
    
    console.log(this.state);
    return (
      <div className="container-fluid main-app position-absolute">
        {/* <Preloader/> */}
        <div className="main-app__wrap row justify-content-center">
          <div className="col-md-10 col-lg-7 col-11 justify-content-center main-app__main-col position-relative h-100">
            {/* <div className="log position-absolute">
              {root.getBoundingClientRect().height}
              <br />
              {window.innerHeight}
              <br/>

            </div> */}
            <Header />
            <hr />
            <div className="content position-relative">
              <Back />
              {this.state.currentFrame}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}



export default App;