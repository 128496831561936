import {Component} from "react";

class Footer extends Component {

  render(){
    return (
      <div className="container-fluid footer-container">
        <div className="row justify-content-center">
          <div className="col-md-7 col-12">
            <div className="row">
              <div className="col-4">
                <div className="inner-col d-flex">
                  <a
                    href="https://www.instagram.com/zolotoyvek.ua/"
                    className="d-flex justify-content-start"
                    target="_blank"
                  >
                    <span className="footer-icon">
                      <svg
                        fill="#ffffff"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                        width="100px"
                        height="100px"
                      >
                        <path d="M69.961,17.654h-39c-7.168,0-13,5.832-13,13v39c0,7.168,5.832,13,13,13h39c7.168,0,13-5.832,13-13v-39C82.961,23.485,77.129,17.654,69.961,17.654z M80.961,69.654c0,6.065-4.935,11-11,11h-39c-6.065,0-11-4.935-11-11v-39c0-6.065,4.935-11,11-11h39c6.065,0,11,4.935,11,11V69.654z" />
                        <path d="M77.461,47.654c-0.276,0-0.5,0.224-0.5,0.5v18.663c0,5.424-4.413,9.837-9.837,9.837H33.798c-5.424,0-9.837-4.413-9.837-9.837V33.49c0-5.424,4.413-9.837,9.837-9.837h33.663c0.276,0,0.5-0.224,0.5-0.5s-0.224-0.5-0.5-0.5H33.798c-5.976,0-10.837,4.861-10.837,10.837v33.326c0,5.976,4.861,10.837,10.837,10.837h33.326c5.976,0,10.837-4.861,10.837-10.837V48.154C77.961,47.877,77.737,47.654,77.461,47.654z" />
                        <path d="M77.461 41.654c-.276 0-.5.224-.5.5v4c0 .276.224.5.5.5s.5-.224.5-.5v-4C77.961 41.877 77.737 41.654 77.461 41.654zM76.961 38.154v2c0 .276.224.5.5.5s.5-.224.5-.5v-2c0-.276-.224-.5-.5-.5S76.961 37.877 76.961 38.154zM62.458 37.264c-.815-.81-1.662-1.357-2.746-1.776-1.09-.423-2.228-.64-3.719-.705-1.535-.066-2.052-.088-5.993-.088-3.979 0-4.465.011-6.024.09-1.459.063-2.597.28-3.688.704-1.085.418-1.932.966-2.746 1.776-.815.81-1.367 1.652-1.788 2.729-.426 1.084-.645 2.215-.709 3.694-.067 1.526-.089 2.04-.089 5.959 0 3.955.011 4.438.09 5.986.063 1.451.282 2.582.708 3.667.421 1.078.973 1.92 1.788 2.73.814.81 1.661 1.357 2.746 1.776 1.09.423 2.228.64 3.719.705C45.542 64.577 46.059 64.6 50 64.6c3.979 0 4.465-.011 6.023-.09 1.459-.063 2.597-.28 3.688-.704 1.084-.418 1.931-.966 2.746-1.776.815-.81 1.367-1.652 1.788-2.729.426-1.084.645-2.215.709-3.689.067-1.526.09-2.041.09-5.964 0-3.956-.011-4.439-.091-5.986-.063-1.452-.282-2.583-.708-3.668C63.824 38.916 63.273 38.074 62.458 37.264zM63.955 55.59c-.059 1.337-.256 2.369-.641 3.347-.368.941-.849 1.677-1.562 2.385-.711.707-1.452 1.186-2.402 1.552-.986.382-2.025.579-3.374.637C54.437 63.589 53.955 63.6 50 63.6c-3.92 0-4.432-.022-5.98-.089-1.347-.059-2.386-.255-3.371-.637-.95-.367-1.69-.845-2.401-1.552-.712-.708-1.193-1.443-1.562-2.386-.384-.979-.582-2.01-.64-3.35-.078-1.529-.089-2.008-.089-5.939 0-3.898.022-4.406.089-5.942.059-1.337.256-2.37.641-3.347.368-.942.85-1.678 1.562-2.385.711-.707 1.452-1.186 2.402-1.552.986-.382 2.025-.579 3.374-.637 1.54-.078 2.022-.089 5.977-.089 3.919 0 4.431.022 5.98.089 1.347.059 2.386.255 3.371.637.949.366 1.69.845 2.401 1.552.712.708 1.194 1.443 1.562 2.385l0 .001c.384.978.582 2.009.64 3.349.079 1.528.09 2.007.09 5.938C64.045 53.549 64.022 54.058 63.955 55.59z" />
                        <path d="M49.989 41.72c-4.397 0-7.975 3.556-7.975 7.927 0 4.371 3.578 7.927 7.975 7.927s7.974-3.556 7.974-7.927C57.963 45.277 54.386 41.72 49.989 41.72zM49.989 56.574c-3.846 0-6.975-3.107-6.975-6.927 0-3.819 3.129-6.927 6.975-6.927s6.974 3.107 6.974 6.927C56.963 53.467 53.835 56.574 49.989 56.574zM58.03 39.195c-1.236 0-2.242 1.001-2.242 2.231 0 1.23 1.006 2.231 2.242 2.231s2.242-1.001 2.242-2.231C60.273 40.196 59.267 39.195 58.03 39.195zM58.03 42.657c-.685 0-1.242-.552-1.242-1.231 0-.679.557-1.231 1.242-1.231s1.242.552 1.242 1.231C59.273 42.105 58.716 42.657 58.03 42.657z" />
                      </svg>
                    </span>
                    <span className="footer-text d-flex align-items-center">
                      <p className="m-0">Instargam</p>
                    </span>
                  </a>
                </div>
              </div>
              <div className="col-4">
                <div className="inner-col d-flex">
                  <a
                    href="https://www.facebook.com/zolotoyvek.ua"
                    className="d-flex justify-content-start"
                    target="_blank"
                  >
                    <span className="footer-icon">
                      <svg
                        fill="#fff"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                        width="100px"
                        height="100px"
                      >
                        <path
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeMiterlimit="10"
                          d="M79.484 57.186c-.4 1.734-.957 3.441-1.671 5.098-.182.422-.374.84-.576 1.255M78.411 39.02c1.488 3.808 2.186 7.86 2.085 11.898-.017.659-.054 1.318-.113 1.975M27.735 29.228c5.171-5.538 11.932-8.707 18.926-9.462M29.278 72.284c-10.538-9.839-12.565-25.37-5.752-37.341M75.203 67.306c-.852 1.241-1.806 2.434-2.865 3.568-2.642 2.83-5.7 5.041-8.991 6.628"
                        />
                        <path
                          fill="none"
                          stroke="#fff"
                          strokeMiterlimit="10"
                          d="M44.5,84V59.5h-9v-9h9l0-8.971c-0.195-8.049,5.897-15.88,20-11.538L64.471,38.5l-4.901-0.032c-3.77,0-5.069,1.246-5.069,3.962v8.07h10.776l-1.894,9H54.5V84"
                        />
                        <path
                          fill="none"
                          stroke="#fff"
                          strokeMiterlimit="10"
                          strokeWidth="2"
                          d="M50 15A35 35 0 1 0 50 85A35 35 0 1 0 50 15Z"
                        />
                      </svg>
                    </span>
                    <span className="footer-text d-flex align-items-center">
                      <p className="m-0">Facebook</p>
                    </span>
                  </a>
                </div>
              </div>
              <div className="col-4">
                <div className="inner-col d-flex">
                  <a
                    href="https://www.youtube.com/c/%D0%97%D0%BE%D0%BB%D0%BE%D1%82%D0%B8%D0%B9%D0%92%D1%96%D0%BA"
                    className="d-flex justify-content-start"
                    target="_blank"
                  >
                    <span className="footer-icon">
                      <svg
                        fill="#fff"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                        width="100px"
                        height="100px"
                      >
                        <path d="M69.921,17.654h-39c-7.168,0-13,5.832-13,13v39c0,7.168,5.832,13,13,13h39c7.168,0,13-5.832,13-13v-39C82.921,23.485,77.089,17.654,69.921,17.654z M80.921,69.654c0,6.065-4.935,11-11,11h-39c-6.065,0-11-4.935-11-11v-39c0-6.065,4.935-11,11-11h39c6.065,0,11,4.935,11,11V69.654z" />
                        <path d="M77.421,47.654c-0.276,0-0.5,0.224-0.5,0.5v18.663c0,5.424-4.413,9.837-9.837,9.837H33.758c-5.424,0-9.837-4.413-9.837-9.837V33.49c0-5.424,4.413-9.837,9.837-9.837h33.663c0.276,0,0.5-0.224,0.5-0.5s-0.224-0.5-0.5-0.5H33.758c-5.976,0-10.837,4.861-10.837,10.837v33.326c0,5.976,4.861,10.837,10.837,10.837h33.326c5.976,0,10.837-4.861,10.837-10.837V48.154C77.921,47.877,77.698,47.654,77.421,47.654z" />
                        <path d="M77.421 41.654c-.276 0-.5.224-.5.5v4c0 .276.224.5.5.5s.5-.224.5-.5v-4C77.921 41.877 77.698 41.654 77.421 41.654zM76.921 38.154v2c0 .276.224.5.5.5s.5-.224.5-.5v-2c0-.276-.224-.5-.5-.5S76.921 37.877 76.921 38.154zM65.202 39.456c-1.229-1.373-2.596-1.511-3.329-1.585l-.21-.022C57.215 37.504 50.495 37.5 50.413 37.5c-.067 0-6.788.004-11.256.351l-.188.02c-.733.074-2.099.212-3.324 1.579-1.055 1.14-1.387 3.561-1.401 3.671-.003.028-.323 2.822-.323 5.58v2.588c0 2.76.32 5.553.324 5.589.014.103.345 2.522 1.396 3.654 1.135 1.265 2.554 1.447 3.401 1.557.153.02.288.037.447.066 2.554.261 10.586.342 10.933.346.067 0 6.794-.016 11.265-.359l.193-.021c.732-.077 2.096-.219 3.315-1.581 1.056-1.138 1.388-3.557 1.402-3.668.003-.028.323-2.822.323-5.581v-2.588c0-2.759-.32-5.553-.323-5.581-.001-.002-.001-.005-.001-.008C66.583 43.011 66.252 40.59 65.202 39.456zM65.921 51.29c0 2.7-.313 5.438-.316 5.458-.081.611-.446 2.361-1.147 3.118-.969 1.081-2.04 1.193-2.681 1.26l-.191.021C57.177 61.484 50.488 61.5 50.426 61.5c-.083-.001-8.326-.084-10.788-.333-.131-.026-.289-.047-.468-.07-.756-.097-1.898-.244-2.791-1.239-.696-.75-1.061-2.5-1.141-3.103-.004-.027-.317-2.765-.317-5.466v-2.588c0-2.698.313-5.437.316-5.456.081-.611.444-2.361 1.147-3.121.973-1.084 2.045-1.193 2.686-1.258l.188-.021c4.405-.341 11.088-.345 11.17-.345.067 0 6.748.004 11.134.343l.21.023c.641.065 1.714.173 2.69 1.264.695.75 1.06 2.496 1.143 3.111.014.123.316 2.812.316 5.461V51.29z" />
                        <path d="M46.678,43.418c-0.155-0.093-0.348-0.095-0.503-0.006c-0.157,0.089-0.254,0.255-0.254,0.435v12c0,0.18,0.097,0.346,0.254,0.435c0.076,0.043,0.161,0.065,0.246,0.065c0.089,0,0.178-0.024,0.257-0.071l10-6c0.151-0.09,0.243-0.253,0.243-0.429s-0.092-0.338-0.243-0.429L46.678,43.418z M46.921,54.963V44.73l8.528,5.117L46.921,54.963z" />
                      </svg>
                    </span>
                    <span className="footer-text d-flex align-items-center">
                      <p className="m-0">YouTube</p>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}




export default Footer