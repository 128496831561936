import react, { Component } from "react";


class Text extends Component {
    constructor(props){
        super(props);
        this.state = {
          btnClass: "btn typical-text__btn",
          fillerClass: "btn-filler",
        };
        this.next = this.next.bind(this);
    }

    next(){
        
        this.setState({
          fillerClass: "btn-filler btn-next-animation",
        });

       setTimeout(() => {
            // this.setState({
            //   btnClass: "btn typical-text__btn",
            // });

            this.props.next(this._text.value);
       }, 400);
    }

    render() {
        return (
          <div className="typical-text">
            <textarea
              className="form-control"
              name=""
              id=""
              cols="30"
              rows="5"
              ref={(text) => {
                this._text = text;
              }}
            ></textarea>
            <div onClick={this.next} className={this.state.btnClass}>
                <span className={this.state.fillerClass}></span>
              <span>Далi</span>
            </div>
          </div>
        );
    }
}



export default Text;