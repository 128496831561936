    import react, { Component } from "react";


    class Emoji extends Component {

        render() {
            return <div className="typical-emoji-wrap yesno d-flex">
                <span className="typical-emoji-wrap_inner" value-data={this.props.value[0]} onClick={this.props.setEmoji}>
                    <svg strokeWidth="0.501" strokeLinejoin="bevel" fillRule="evenodd" overflow="visible" width="99.264pt" height="99.264pt" viewBox="0 0 99.264 99.264">

                        <g id="Document" fill="none" stroke="black"  transform="scale(1 -1)">
                            <g id="Spread" transform="translate(0 -99.262)">
                                <g id="Layer 1">
                                    <path d="M 17.715,85.674 C 37.61,103.29 68.057,101.442 85.674,81.547 C 103.29,61.652 101.442,31.205 81.547,13.588 C 61.652,-4.028 31.205,-2.18 13.588,17.715 C -4.028,37.61 -2.18,68.057 17.715,85.674 Z" stroke="#af290a" fill="#de654f" strokeWidth="3" strokeLinejoin="miter" markerStart="none" markerEnd="none" strokeMiterlimit="79.8403193612775" />
                                    <path d="M 27.19,63.348 L 63.349,27.188 L 72.073,35.912 L 35.914,72.072 L 27.19,63.348 Z" fill="#af290a" stroke="none" strokeLinecap="round" strokeWidth="0.731" strokeLinejoin="miter" markerStart="none" markerEnd="none" strokeMiterlimit="79.8403193612775" />
                                    <path d="M 27.19,35.914 L 63.349,72.074 L 72.073,63.35 L 35.914,27.19 L 27.19,35.914 Z" fill="#af290a" stroke="none" strokeLinecap="round" strokeWidth="0.731" strokeLinejoin="miter" markerStart="none" markerEnd="none" strokeMiterlimit="79.8403193612775" />
                                </g>
                            </g>
                        </g>
                    </svg>
                </span>
                <span className="typical-emoji-wrap_inner" value-data={this.props.value[1]} onClick={this.props.setEmoji}>
                    <svg style={{transform:"scale(-1, 1)"}} strokeWidth="0.501" strokeLinejoin="bevel" fillRule="evenodd" overflow="visible" width="99.264pt" height="99.264pt" viewBox="0 0 99.264 99.264">

                        <g id="Document" fill="none" stroke="black"  transform="scale(1 -1)">
                            <g id="Spread" transform="translate(0 -99.262)">
                                <g id="Layer 1">
                                    <path d="M 17.715,85.674 C 37.61,103.29 68.057,101.442 85.674,81.547 C 103.29,61.652 101.442,31.205 81.547,13.588 C 61.652,-4.028 31.205,-2.18 13.588,17.715 C -4.028,37.61 -2.18,68.057 17.715,85.674 Z" stroke="#6ea337" strokeWidth="3" fill="#a1d07a" strokeLinejoin="miter" markerStart="none" markerEnd="none" strokeMiterlimit="79.8403193612775" />
                                    <path d="M 21.232,60.734 L 52.388,29.578 L 61.338,38.528 L 30.182,69.684 L 21.232,60.734 Z" fill="#6ea337" stroke="none" strokeLinecap="round" strokeWidth="0.75" strokeLinejoin="miter" markerStart="none" markerEnd="none" strokeMiterlimit="79.8403193612775" />
                                    <path d="M 69.08,64.17 L 43.691,38.781 L 52.641,29.831 L 78.03,55.22 L 69.08,64.17 Z" fill="#6ea337" stroke="none" strokeLinecap="round" strokeWidth="0.75" strokeLinejoin="miter" markerStart="none" markerEnd="none" strokeMiterlimit="79.8403193612775" />
                                </g>
                            </g>
                        </g>
                    </svg>
                </span>
            </div>
        }
    }



    export default Emoji;