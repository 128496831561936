import react, { Component } from "react";


class Preloader extends Component {

  



  render(){
    return <img src="smiles/back.png" alt="" className="img-fluid w-100"/>
  }
}



export default Preloader