import react, { Component } from "react";

class Header extends Component {
    render(){
        return <div className="row main-app__header-row justify-content-center p-3">
            <div className="col-10 h-100">
                <div className="inner-col h-100 d-flex justify-content-center"><a href="https://zolotoyvek.ua/ua/" target="_blank" className="h-100 d-flex justify-content-center"><img src="/smiles/logo.png" alt="" className=""/></a></div>
            </div>
        </div>
    }
}


export default Header