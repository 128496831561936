import react, { Component } from "react";


class Emoji extends Component {

    render() {
        let style = {
            bad : {
                background : '#f2c1c1'
            }
        }
        return <div className="typical-emoji-wrap d-flex">
            <span  className="typical-emoji-wrap_inner bg-bad" value-data={this.props.value[0]} onClick={this.props.setEmoji}>
                <svg strokeWidth="0.501" strokeLinejoin="bevel" fillRule="evenodd" overflow="visible" width="99.264pt" height="99.264pt" viewBox="0 0 99.264 99.264">

                    <g id="Document" fill="none" stroke="black" transform="scale(1 -1)">
                        <g id="Spread" transform="translate(0 -99.264)">
                            <g id="Layer 1">
                                <path d="M 17.716,85.675 C 37.611,103.292 68.058,101.443 85.675,81.548 C 103.292,61.653 101.443,31.206 81.548,13.589 C 61.653,-4.028 31.206,-2.179 13.589,17.716 C -4.028,37.611 -2.179,68.058 17.716,85.675 Z" stroke="#af290a" fill="#de654f" strokeWidth="3" strokeLinejoin="miter" markerStart="none" markerEnd="none" strokeMiterlimit="79.8403193612775" />
                                <path d="M 26.813,68.204 C 28.775,70.82 32.49,71.351 35.106,69.389 C 37.722,67.427 38.253,63.712 36.291,61.096 C 34.329,58.48 30.614,57.949 27.998,59.911 C 25.382,61.873 24.851,65.588 26.813,68.204 Z" fill="#ad2a0c" stroke="none" strokeLinejoin="miter" strokeWidth="0.632" markerStart="none" markerEnd="none" strokeMiterlimit="79.8403193612775" />
                                <path d="M 61.221,68.204 C 63.183,70.82 66.898,71.351 69.514,69.389 C 72.13,67.427 72.661,63.712 70.699,61.096 C 68.737,58.48 65.022,57.949 62.406,59.911 C 59.79,61.873 59.259,65.588 61.221,68.204 Z" fill="#ad2a0c" stroke="none" strokeLinejoin="miter" strokeWidth="0.632" markerStart="none" markerEnd="none" strokeMiterlimit="79.8403193612775" />
                                <path d="M 34.622,32.979 C 43.749,41.457 58.342,40.61 65.98,33.505" fill="none" stroke="#a82609" strokeWidth="3" strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="79.8403193612775" />
                            </g>
                        </g>
                    </g>
                </svg>
            </span>
            <span className="typical-emoji-wrap_inner bg-notbad" value-data={this.props.value[1]} onClick={this.props.setEmoji}>
                <svg strokeWidth="0.501" strokeLinejoin="bevel" fillRule="evenodd" overflow="visible" width="99.264pt" height="99.264pt" viewBox="0 0 99.264 99.264">

                    <g id="Document" fill="none" stroke="black" transform="scale(1 -1)">
                        <g id="Spread" transform="translate(0 -99.264)">
                            <g id="Layer 1">
                                <path d="M 17.716,85.675 C 37.611,103.292 68.058,101.443 85.675,81.548 C 103.292,61.653 101.443,31.206 81.548,13.589 C 61.653,-4.028 31.206,-2.179 13.589,17.716 C -4.028,37.611 -2.179,68.058 17.716,85.675 Z" fill="#ffc83d" stroke="#d49800" strokeWidth="3" strokeLinejoin="miter" markerStart="none" markerEnd="none" strokeMiterlimit="79.8403193612775" />
                                <path d="M 26.813,68.204 C 28.775,70.82 32.49,71.351 35.106,69.389 C 37.722,67.427 38.253,63.712 36.291,61.096 C 34.329,58.48 30.614,57.949 27.998,59.911 C 25.382,61.873 24.851,65.588 26.813,68.204 Z" fill="#d49800" stroke="none" strokeLinejoin="miter" strokeWidth="0.632" markerStart="none" markerEnd="none" strokeMiterlimit="79.8403193612775" />
                                <path d="M 61.221,68.204 C 63.183,70.82 66.898,71.351 69.514,69.389 C 72.13,67.427 72.661,63.712 70.699,61.096 C 68.737,58.48 65.022,57.949 62.406,59.911 C 59.79,61.873 59.259,65.588 61.221,68.204 Z" fill="#d49800" stroke="none" strokeLinejoin="miter" strokeWidth="0.632" markerStart="none" markerEnd="none" strokeMiterlimit="79.8403193612775" />
                                <path d="M 33.976,35.744 L 65.289,35.557" fill="none" stroke="#d49c01" strokeLinejoin="round" strokeLinecap="round" strokeWidth="3" strokeMiterlimit="79.8403193612775" />
                            </g>
                        </g>
                    </g>
                </svg>
            </span>
            <span className="typical-emoji-wrap_inner bg-norm" value-data={this.props.value[2]} onClick={this.props.setEmoji}>
                <svg strokeWidth="0.501" strokeLinejoin="bevel" fillRule="evenodd" overflow="visible" width="99.264pt" height="99.264pt" viewBox="0 0 99.264 99.264">

                    <g id="Document" fill="none" stroke="black" transform="scale(1 -1)">
                        <g id="Spread" transform="translate(0 -99.264)">
                            <g id="Layer 1">
                                <path d="M 17.716,85.675 C 37.611,103.292 68.058,101.443 85.675,81.548 C 103.292,61.653 101.443,31.206 81.548,13.589 C 61.653,-4.028 31.206,-2.179 13.589,17.716 C -4.028,37.611 -2.179,68.058 17.716,85.675 Z" fill="#f8e23b" stroke="#cbb104" strokeWidth="3" strokeLinejoin="miter" markerStart="none" markerEnd="none" strokeMiterlimit="79.8403193612775" />
                                <path d="M 26.813,68.204 C 28.775,70.82 32.49,71.351 35.106,69.389 C 37.722,67.427 38.253,63.712 36.291,61.096 C 34.329,58.48 30.614,57.949 27.998,59.911 C 25.382,61.873 24.851,65.588 26.813,68.204 Z" fill="#d0b100" stroke="none" strokeLinejoin="miter" strokeWidth="0.632" markerStart="none" markerEnd="none" strokeMiterlimit="79.8403193612775" />
                                <path d="M 61.221,68.204 C 63.183,70.82 66.898,71.351 69.514,69.389 C 72.13,67.427 72.661,63.712 70.699,61.096 C 68.737,58.48 65.022,57.949 62.406,59.911 C 59.79,61.873 59.259,65.588 61.221,68.204 Z" fill="#d0b100" stroke="none" strokeLinejoin="miter" strokeWidth="0.632" markerStart="none" markerEnd="none" strokeMiterlimit="79.8403193612775" />
                                <path d="M 36.283,36.954 C 46.979,31.751 55.984,32.827 63.92,37.487" fill="none" stroke="#ccb311" strokeWidth="3" strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="79.8403193612775" />
                            </g>
                        </g>
                    </g>
                </svg>
            </span>
            <span className="typical-emoji-wrap_inner bg-good" value-data={this.props.value[3]} onClick={this.props.setEmoji}>
                <svg strokeWidth="0.501" strokeLinejoin="bevel" fillRule="evenodd" overflow="visible" width="99.264pt" height="99.264pt" viewBox="0 0 99.264 99.264">

                    <g id="Document" fill="none" stroke="black" transform="scale(1 -1)">
                        <g id="Spread" transform="translate(0 -99.264)">
                            <g id="Layer 1">
                                <path d="M 17.716,85.675 C 37.611,103.292 68.058,101.443 85.675,81.548 C 103.292,61.653 101.443,31.206 81.548,13.589 C 61.653,-4.028 31.206,-2.179 13.589,17.716 C -4.028,37.611 -2.179,68.058 17.716,85.675 Z" stroke="#6ea337" strokeWidth="3" fill="#a1d07a" strokeLinejoin="miter" markerStart="none" markerEnd="none" strokeMiterlimit="79.8403193612775" />
                                <path d="M 26.813,68.204 C 28.775,70.82 32.49,71.351 35.106,69.389 C 37.722,67.427 38.253,63.712 36.291,61.096 C 34.329,58.48 30.614,57.949 27.998,59.911 C 25.382,61.873 24.851,65.588 26.813,68.204 Z" fill="#67a538" stroke="none" strokeLinejoin="miter" strokeWidth="0.632" markerStart="none" markerEnd="none" strokeMiterlimit="79.8403193612775" />
                                <path d="M 61.221,68.204 C 63.183,70.82 66.898,71.351 69.514,69.389 C 72.13,67.427 72.661,63.712 70.699,61.096 C 68.737,58.48 65.022,57.949 62.406,59.911 C 59.79,61.873 59.259,65.588 61.221,68.204 Z" fill="#67a538" stroke="none" strokeLinejoin="miter" strokeWidth="0.632" markerStart="none" markerEnd="none" strokeMiterlimit="79.8403193612775" />
                                <path d="M 35.506,28.692 C 30.925,32.127 27.902,36.871 26.565,42.017 L 73.637,42.017 C 72.865,39.042 71.512,36.166 69.555,33.557 C 61.5,22.817 46.246,20.637 35.506,28.692 Z" fill="#67a538" strokeLinejoin="miter" stroke="none" strokeWidth="2.594" markerStart="none" markerEnd="none" strokeMiterlimit="79.8403193612775" />
                            </g>
                        </g>
                    </g>
                </svg>
            </span>
            {/* <span className="typical-emoji-wrap_inner bg-good" onClick={this.props.setEmoji}>
                <svg strokeWidth="0.501" strokeLinejoin="bevel" fillRule="evenodd" overflow="visible" width="99.264pt" height="99.264pt" viewBox="0 0 99.264 99.264">

                    <g id="Document" fill="none" stroke="black" transform="scale(0.96 -0.96)">
                        <g id="Spread" transform="translate(0 -102.7)">
                            <g id="Layer 1">
       <path d="M 18.381,88.893 C 39.023,107.172 70.614,105.253 88.893,84.611 C 107.172,63.969 105.253,32.378 84.611,14.099 C 63.969,-4.18 32.378,-2.261 14.099,18.381 C -4.18,39.023 -2.261,70.614 18.381,88.893 Z" stroke="#3e9e47" fill="#86cc8f" strokeWidth="3.113" marker-start="none" marker-end="none" stroke-miterlimit="372.451097804391"/>
       <path d="M 36.839,29.769 C 32.086,33.333 28.95,38.255 27.563,43.594 L 76.403,43.594 C 75.602,40.508 74.198,37.524 72.168,34.817 C 63.81,23.673 47.982,21.411 36.839,29.769 Z" fill="#3d9d46" stroke="none" strokeWidth="2.692" marker-start="none" marker-end="none" stroke-miterlimit="372.451097804391"/>
       <path d="M 58.398,72.086 L 62.838,66.13 L 60.449,59.096 L 67.484,61.479 L 73.435,57.034 L 73.344,64.461 L 79.411,68.747 L 72.318,70.956 L 70.117,78.05 L 65.825,71.987 L 58.398,72.086 Z" stroke="none" fill="#3d9d46" strokeWidth="0.761" marker-start="none" marker-end="none" stroke-miterlimit="372.451097804391"/>
       <path d="M 21.785,68.35 L 27.947,64.201 L 28.022,56.772 L 33.872,61.351 L 40.959,59.127 L 38.413,66.105 L 42.718,72.159 L 35.295,71.894 L 30.868,77.859 L 28.826,70.717 L 21.785,68.35 Z" stroke="none" fill="#3d9d46" strokeWidth="0.761" marker-start="none" marker-end="none" stroke-miterlimit="372.451097804391"/>

   </g>
                        </g>
                    </g>
                </svg>
            </span> */}
        </div>
    }
}



export default Emoji;