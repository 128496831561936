import react, { Component } from "react";
import Emoji from "./Typical/Emoji";
import VerticalEmoji from "./Typical/VerticalEmoji";
import YesNo from "./Typical/YesNo";
import Text from "./Typical/Text";


class Typical extends Component {
    constructor(props) {
        super(props);
        this.state = {
            typicaltStyle: {
                opacity: '0'
            }
        }
        this.Next = this.Next.bind(this);
        this.setEmoji = this.setEmoji.bind(this);
        this.setEmojiVertival = this.setEmojiVertival.bind(this);
    }

    Next(e) {
        // console.log('hello');
        let data = {q: this.props.data.title, a: e};
        console.log(data);
        setTimeout(() => {
            this.setState({
                typicaltStyle: {
                    transform: 'translateX(-120%) rotateZ(-5deg)',
                    opacity: '0'
                }
            })
            setTimeout(() => {
                this.props.change(data);
            }, 200);
        }, 400);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props !== prevProps) {
            this.setState({
                typicaltStyle: {
                    opacity: '1',
                    transform: 'none'
                }
            })
        }
    }


    componentDidMount() {
        setTimeout(() => {
            this.setState({
                typicaltStyle: {
                    opacity: '1'
                }
            })
        }, 100);
    }

    setEmoji(e){
        let data = {q: this.props.data.title, a: e.currentTarget.getAttribute('value-data')};
        // console.info(data);
        let nodes = e.currentTarget.parentNode.querySelectorAll('.typical-emoji-wrap_inner');
        let pw = e.currentTarget.parentNode.offsetWidth;
        let myw = e.currentTarget.offsetWidth;
        let diff = (pw - myw) / 2 - e.currentTarget.offsetLeft;
        // console.log();
        e.currentTarget.style.transform = 'translateX(' + (diff + 20) + 'px) scale(1.3)';
        e.currentTarget.style.zIndex = '10';
        // console.log(e.currentTarget.parentNode); 
        // console.log(e.currentTarget);
        nodes.forEach(element => {
            element.classList.add('not-choosen');
            element.classList.remove('choosen');
        });
        e.currentTarget.classList.remove('not-choosen');
        e.currentTarget.classList.add('choosen');

        setTimeout(() => {
            this.setState({
                typicaltStyle: {
                    transform: 'translateX(-120%) rotateZ(-5deg)',
                    opacity: '0'
                }
            })
            setTimeout(() => {
                this.props.change(data);
            }, 200);
        }, 400);
    }

    setEmojiVertival(e){
        let data = {q: this.props.data.title, a: e.currentTarget.getAttribute('value-data')};
        // console.info(data);
        let wrap = e.currentTarget.querySelector('.emoji-img');
        let nodes = e.currentTarget.parentNode.querySelectorAll('.typical-emoji-wrap_inner');
        let pw = e.currentTarget.parentNode.clientWidth;
        let ph = e.currentTarget.parentNode.clientHeight;
        let myw = e.currentTarget.querySelector('.emoji-img').clientWidth;
        let myh = e.currentTarget.querySelector('.emoji-img').clientHeight;
        let mytw = e.currentTarget.querySelector('.emoji-text').clientWidth;
        let myth = e.currentTarget.querySelector('.emoji-text').clientHeight;
        // console.log(e.currentTarget.querySelector('.emoji-img'));
        // console.log(ph);
        // console.log(myh);
        // console.log(e.currentTarget.offsetTop);
        let diffW = (pw - myw) / 2 - e.currentTarget.offsetLeft;
        let diffH =  (e.currentTarget.offsetTop - (ph/2)) + (myh/2);
        let difftW = (pw - mytw) / 2 - e.currentTarget.offsetLeft;
        let difftH =  (e.currentTarget.offsetTop - (ph/2)) + (myth/2);
        // console.log(diffH);
        if (wrap.clientWidth < wrap.offsetLeft) {
            diffW *= -1;
            diffW -=40
            myw *=-1;
            myw +=40
        }
        if (wrap.clientHeight > wrap.offsetTop) {
            diffH *= -1;
        }
        console.log('translateX(' + (diffW + 20) + 'px) translateY(-'+diffH+'px) scale(1.3) ');
        e.currentTarget.querySelector('.emoji-img').style.transform = 'translateX(' + (diffW + 20) + 'px) translateY('+diffH+'px) scale(1.3) ';
        e.currentTarget.querySelector('.emoji-text').style.transform = 'translateX(' + (difftW - 40 - myw) + 'px) translateY('+ ((difftH - myh - 20) * -1 )+'px) scale(1.3) ';
        e.currentTarget.querySelector('.emoji-text').style.cssText = 'position:absolute;width:100%; transform:' + ' translateY('+ ((difftH - myh - 20) * -1 )+'px) scale(1.3) ';
        console.log(mytw);
        e.currentTarget.style.zIndex = '10';
        // console.log(e.currentTarget.parentNode); 
        // console.log(e.currentTarget);
        nodes.forEach(element => {
            element.querySelector(' .emoji-img').classList.add('not-choosen');
            element.querySelector(' .emoji-img').classList.remove('choosen');
            element.querySelector(' .emoji-text').classList.add('not-choosen');
            element.querySelector(' .emoji-text').classList.remove('choosen');
        });
        e.currentTarget.querySelector('.emoji-img').classList.remove('not-choosen');
        e.currentTarget.querySelector('.emoji-img').classList.add('choosen');
        e.currentTarget.querySelector('.emoji-text').classList.remove('not-choosen');
        e.currentTarget.querySelector('.emoji-text').classList.add('choosen');

        setTimeout(() => {
            this.setState({
                typicaltStyle: {
                    transform: 'translateX(-120%) rotateZ(-5deg)',
                    opacity: '0'
                }
            })
            setTimeout(() => {
                this.props.change(data);
            }, 300);
        }, 600);
    }


    

    render() {
        let types = {
            "emoji" : <Emoji value={this.props.data.values} setEmoji={this.setEmoji}/>,
            "emojiV" : <VerticalEmoji value={this.props.data.values} setEmoji={this.setEmojiVertival}/>,
            "yesno" : <YesNo value={this.props.data.values} setEmoji={this.setEmoji}/>,  
            "text" : <Text next={this.Next}/>,
        };

        console.log('render Typical');
        console.log(this.props);
        return <div style={this.state.typicaltStyle} className="card typical-card" >
            <div className="card-body">
                <h5 className="card-title">{this.props.data.title}</h5>
                <p className="card-text">{this.props.data.text}</p>
                {/* <div onClick={this.Next} className="btn btn-primary">Переход куда-нибудь</div> */}
                {types[this.props.data.type]}
            </div>
        </div>
    }
}



export default Typical;