import react, { Component } from "react";


class Back extends Component {

    render() {
        return <div className="main-app__background position-absolute w-100">
            <img src="smiles/back.png" alt="" className="" />
            </div>

    }
}


 
export default Back;