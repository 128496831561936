import react, { Component } from "react";


class Start extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startStyle: {
                transform: 'translateX(520%) rotateZ(15deg)'
            },
            currentFrame: null
        }
        this.Next = this.Next.bind(this);
    }
    componentDidMount(){
        setTimeout(() => {
            this.setState({
                startStyle: {
                    transform: 'translateX(0) rotateZ(0deg)'
                }
            })
        }, 500);
    }

    Next(e) {
        // console.log('hello');
        let btn =  e.currentTarget;
        e.currentTarget.querySelector('.start-btn__text').classList.add('flex-anima-low');
        e.currentTarget.querySelector('.start-btn__icon').classList.add('flex-anima');


        // setTimeout(() => {
        //      btn.querySelector('.start-btn__text').classList.remove('flex-anima-low');
        //     btn.querySelector('.start-btn__icon').classList.remove('flex-anima');
        // }, 1000);
        
        setTimeout(() => {
            this.setState({
                startStyle: {
                    transform: 'translateX(-120%) rotateZ(-5deg)'
                }
            })
        }, 500);
        setTimeout(() => {
            this.props.change(); 
        }, 700);
    }

    render() {
        return <div style={this.state.startStyle} className="card" >
            <div className="card-body">
                <h5 className="card-title font-weight-bold">👋🏼Вітаємо!</h5>
                <p className="card-text">Оцініть, будь ласка, якість роботи продавця, який Вас обслуговував</p>
                <div onClick={this.Next} className="start-btn"><span className="start-btn__icon d-flex"><svg viewBox="0 -1 19 19" className="bi bi-arrow-right-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path fillRule="evenodd" d="M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z" />
                </svg></span><span className="start-btn__text">Почати</span> </div>
            </div>
        </div>
    }
}



export default Start;